<template>
<div class="login-page">
  <a-row>
    <a-col :span="12" class="aside">
      <div class="aside-inner">
        <router-link to="/">
          <img alt="龙骨动画" src="../assets/logo2.png" class="logo-img">
        </router-link>
        <h2>龙骨动画，让世界动起来</h2>
        <span class="text-white-70">动画大师, 王某某</span>
      </div>
    </a-col>
    <a-col :span="12" class="login-area">
      <a-form layout="vertical" :model="form" :rules="rules" ref="loginForm">
        <h2>欢迎回来</h2>
        <p class="subTitle">使用手机号码和密码登录到龙骨动画</p>
        <a-form-item label="手机号码" required name="cellphone">
          <a-input placeholder="手机号码" v-model:value="form.cellphone">
            <template v-slot:prefix><UserOutlined style="color:rgba(0,0,0,.25)"/></template>
          </a-input>
        </a-form-item>
        <a-form-item label="密码" required name="password">
          <a-input placeholder="至少6位" v-model:value="form.password" type="password">
            <template v-slot:prefix><LockOutlined style="color:rgba(0,0,0,.25)"/></template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" size="large" @click="login" :loading="isLoginLoading" class="login-btn">
            登录
          </a-button>
          <a-button type="primary" size="large" @click="gotoForgetPassword" :loading="isLoginLoading" class="login-btn">
            忘记密码
          </a-button>
          <a-button v-if="isLoginError" type="primary" size="large" @click="gotoSignup" :loading="isLoginLoading" class="login-btn">
            注册
          </a-button>
        </a-form-item>
      </a-form>
    </a-col>
  </a-row>
</div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, Ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useForm } from '@ant-design-vue/use'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { Rule } from 'ant-design-vue/es/form/interface'
import { GlobalDataProps } from '../store/index'
interface RuleFormInstance {
  validate: () => Promise<any>;
}
export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined
  },
  setup () {
    const store = useStore<GlobalDataProps>()
    const isLoginLoading = computed(() => store.getters.isOpLoading('login'))
    const router = useRouter()
    const counter = ref(60)
    const isLoginError = ref(false)
    const timer = 0
    const loginForm = ref() as Ref<RuleFormInstance>
    const form = reactive({
      cellphone: '',
      password: ''
    })
    const codeButtonDisable = computed(() => {
      return !/^1[3-9]\d{9}$/.test(form.cellphone.trim()) || (counter.value < 60)
    })
    watch(counter, (newValue) => {
      if (newValue === 0) {
        clearInterval(timer)
        counter.value = 60
      }
    })
    const cellnumberValidator = (rule: Rule, value: string) => {
      return new Promise((resolve, reject) => {
        const passed =  /^1[3-9]\d{9}$/.test(value.trim())
        setTimeout(() => {
          if (passed) {
            resolve('')
          } else {
            reject('手机号码格式不正确')
          }
        }, 500)
      })
    }
    const rules = reactive({
      cellphone: [
        { required: true, message: '手机号码不能为空', trigger: 'blur' },
        // { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        { asyncValidator: cellnumberValidator, trigger: 'blur' }
      ],
      password: [
        { required: true, message: '密码不能为空', trigger: 'blur' },
        { min: 6, message: '密码至少6位', trigger: 'blur' }
      ]
    })

    const { validate } = useForm(form, rules)
    const login = () => {
      validate().then(() => {
        const payload = {
          phoneNumber: form.cellphone,
          password: form.password
        }
        store.dispatch('loginAndFetch', { data: payload }).then(() => {
          message.success('登录成功 2秒后跳转首页')
          setTimeout(() => {
            router.push('/')
          }, 2000)
        }).catch(() => {
          message.error('登录失败')
          isLoginError.value = true
        })
      })
    }
    const gotoForgetPassword = () => {
      router.push('/forgetPassword')
    }
    const gotoSignup = () => {
      router.push('/signup')
    }
    return {
      form,
      rules,
      loginForm,
      login,
      codeButtonDisable,
      counter,
      isLoginLoading,
      isLoginError,
      gotoForgetPassword,
      gotoSignup
    }
  }
})
</script>
<style>
.logo-area {
  position: absolute;
  top: 30px;
  width: 150px;
}
.aside {
  height: 100vh;
  background-color: #1a1919;
  background-size: cover;
  background-repeat: no-repeat;
}
.aside .logo-img {
  width: 200px;
  margin-bottom: 20px;
}
.aside h2 {
  color: #CCCCCC;
  font-size: 29px;
}
.aside-inner {
  width: 60%;
  text-align: center;
}
.login-area {
  height: 100vh;
}
.login-area .ant-form {
  width: 350px;
}
.text-white-70 {
  color: #999;
  display: block;
  font-size: 19px;
}
.aside, .login-area {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.login-area h2 {
  color: #333333;
  font-size: 29px;
}
.login-area .subTitle {
  color: #666666;
  font-size: 19px;
}
.login-area .ant-form-item-label {
  display: none;
}
.login-area .ant-input-prefix {
  left: auto;
  right: 30px;
  font-size: 19px;
}
.login-area .ant-input {
  font-size: 17px;
  padding: 20px 45px 20px 30px;
}
.login-btn {
  margin-right: 10px;
}
</style>
